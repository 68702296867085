import { default as indexXKPTTpM4MvMeta } from "/app/pages/[...slug]/index.vue?macro=true";
import { default as _91uuid_93KmeC8KDkMPMeta } from "/app/pages/blokkli/library-item/[uuid].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexXceFiLCWeMMeta } from "/app/pages/node/[nid]/index.vue?macro=true";
import { default as indexP0q8KYV1WMMeta } from "/app/pages/search/index.vue?macro=true";
import { default as exampleOqlZnZWjmoMeta } from "/app/pages/static-page/example.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexXKPTTpM4MvMeta || {},
    component: () => import("/app/pages/[...slug]/index.vue")
  },
  {
    name: "blokkli-library-item-uuid",
    path: "/blokkli/library-item/:uuid()",
    meta: _91uuid_93KmeC8KDkMPMeta || {},
    component: () => import("/app/pages/blokkli/library-item/[uuid].vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexXceFiLCWeMMeta || {},
    component: () => import("/app/pages/node/[nid]/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexP0q8KYV1WMMeta || {},
    component: () => import("/app/pages/search/index.vue")
  },
  {
    name: "static-page-example",
    path: "/static-page/example",
    meta: exampleOqlZnZWjmoMeta || {},
    component: () => import("/app/pages/static-page/example.vue")
  }
]